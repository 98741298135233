jQuery(document).ready(function() {

  // replace img-svgs to inline svg
  jQuery('.replace-image-with-svg').each(function() {
    var jQueryimg = jQuery(this),
    imgURL = jQueryimg.attr('src'),
    imgID  = jQueryimg.attr('id');
    jQuery.get(imgURL, function(data) {
      var jQuerysvg = jQuery(data).find('svg');
      if(typeof imgID !== 'undefined') {
        jQuerysvg = jQuerysvg.attr('id', imgID);
      }
      jQuerysvg = jQuerysvg.removeAttr('xmlns:a');
      jQueryimg.replaceWith(jQuerysvg);
    }, 'xml');
  });

  jQuery('#menu-top-navigation-primary .empty-link > a').on('click', function(e) {
      e.preventDefault();
  });

  if(jQuery('header.navbar.menu-primary').get().length>0) {
    jQuery('header.navbar.menu-primary .logo-container .navbar-header').append('<a href="https://populationfoundation.in/" class="navbar-brand js-add" target="_blank"><div class="logo-image"><img src="https://awards.populationfoundation.in/wp-content/uploads/2018/09/PFI-logo.svg" alt="logo" width="1" height="1" class="img-responsive"></div></a>').fadeIn();
    jQuery('header.navbar.menu-primary .logo-container').addClass('js-add');
  }

});
